@import url('https://fonts.googleapis.com/css2?family=Cambay:ital,wght@1,700&family=Gudea&display=swap');
@import "https://fonts.googleapis.com/css?family=Share+Tech+Mono";

:root {
  font-size: calc(0.5em + 0.9vw);
  --color-main: #f66;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Gudea, sans-serif;
}

body,
html,
#root,
#App {
  width: 100%;
  height: 100vh;
  background-color: var(--color-main);
  
}

#App{
  display: flex;
  justify-content: center;
  align-items: center;
}