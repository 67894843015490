@import url(https://fonts.googleapis.com/css2?family=Cambay:ital,wght@1,700&family=Gudea&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono);
.selector {
  display: flex;
  align-items: center;
  color: var(--color-main);
  flex-direction: column;
  font-weight: bold;

  justify-content: center;
  font-size: 1em;
}

.selector-buttons {
  display: flex;
  padding: 0.2em;
  width: auto;
}

.selector-increment,
.selector-decrement {
  margin: 0em 0.1em;
  background-color: var(--color-main);
  border: none;
  padding: 0em 0.2em;
  font-size: 1em;
  border-radius: 0.2em;
  cursor: pointer;
  flex: 0 0 2em;
  width: 2em;
}

#settings {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-wrap: wrap;
  font-size: 1.5rem;
  width: 100%;

}

.group {
  display: flex;
  flex-direction: column;
  width: 5em;
  text-align: center;
  justify-content: center;
  align-items: center;

}


#counter {
  display: flex;
  flex-direction: column;
  color: var(--color-main);
  font-size: 3rem;
  justify-content: center;
  align-items: center;
}

#timer-label {
  font-family: "Cambay";
}

#time-left {
  background-color: #eee;
  padding: 0.1em 0.3em;
  margin: 0.4em;
  border-radius: 15px;
  font-size: 1.5em;
  font-family: "Share Tech Mono";
}

#controls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  font-size: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 1em;
  font-size: 1.5rem;
}

.control {

  border-radius: 0.7em;
  padding: 1em;
  margin: 0em 1em;
  background-color: var(--color-main);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.control > i {
  font-size: 1.5em;
}

#clock {
  font-size: 1rem;
  background-color: #222;
  padding: 1em 5em;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  max-width: 95%;
  width: auto;
  position: relative;
}

#clock::after {
  content: "By Nicolas Garay";
  position: relative;
  bottom: 0em;
  width: 100%;
  color: white;
  font-weight: bold;
  text-align: center;
}

:root {
  font-size: calc(0.5em + 0.9vw);
  --color-main: #f66;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Gudea, sans-serif;
}

body,
html,
#root,
#App {
  width: 100%;
  height: 100vh;
  background-color: #f66;
  background-color: var(--color-main);
  
}

#App{
  display: flex;
  justify-content: center;
  align-items: center;
}
