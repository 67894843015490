#counter {
  display: flex;
  flex-direction: column;
  color: var(--color-main);
  font-size: 3rem;
  justify-content: center;
  align-items: center;
}

#timer-label {
  font-family: "Cambay";
}

#time-left {
  background-color: #eee;
  padding: 0.1em 0.3em;
  margin: 0.4em;
  border-radius: 15px;
  font-size: 1.5em;
  font-family: "Share Tech Mono";
}
