.selector {
  display: flex;
  align-items: center;
  color: var(--color-main);
  flex-direction: column;
  font-weight: bold;

  justify-content: center;
  font-size: 1em;
}

.selector-buttons {
  display: flex;
  padding: 0.2em;
  width: auto;
}

.selector-increment,
.selector-decrement {
  margin: 0em 0.1em;
  background-color: var(--color-main);
  border: none;
  padding: 0em 0.2em;
  font-size: 1em;
  border-radius: 0.2em;
  cursor: pointer;
  flex: 0 0 2em;
  width: 2em;
}
